import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class People extends Component {
  render() {
  	const props = this.props
    return (
      <section className='content content--people'>
        <div className='content__inner'>
          <Fade bottom distance='40px'>
            <div className='content__intro' dangerouslySetInnerHTML={{ __html: props.introduction }} />
          </Fade>
          { props.image &&
            <Fade bottom distance='40px'>
              <img src={props.image.localFile.childImageSharp.original.src} alt={props.image.alt_text} />
            </Fade>
          }
          <div className='content__items'>
            { props.team.map((el, i) => {
              return (
                <Fade bottom distance='40px' key={i}>
                  <div className='content__item'>
                    <h3>{ el.name }</h3>
                    <div dangerouslySetInnerHTML={{ __html: el.bio }} />
                  </div>
                </Fade>
              )
            }) }
          </div>
        </div>
      </section>
    )
  }
}

export default People
