import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Image extends Component {

  getImageSrc(obj) {
    let src = obj && obj.localFile && obj.localFile.childImageSharp && obj.localFile.childImageSharp.original.src
    if (obj && obj.source_url.indexOf('.gif') !== -1) {
      src = obj.source_url
    }
    return src
  }

  render() {
  	const { image } = this.props
    return (
      <section className='content content--image'>
        <div className='content__inner'>
          <Fade bottom distance='40px'>
            <img src={this.getImageSrc(image)} alt={image && image.alt_text} />
          </Fade>
        </div>
      </section>
    )
  }
}

export default Image
