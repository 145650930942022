import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Testimonial extends Component {

  render() {
    const { image, quote, name } = this.props

    return (
      <section className='content content--testimonial'>
        <div className='content__inner'>
          { image && image.localFile &&
            <Fade bottom distance='40px'>
              <picture>
                <img src={image.localFile.childImageSharp.original.src} alt={image.alt_text} />
              </picture>
            </Fade>
          }
          <div className='content__quote'>
            <Fade bottom distance='40px'>
              <p>“{ quote }”</p>
              <h5>{ name }</h5>
            </Fade>
          </div>
        </div>
      </section>
    )
  }
}

export default Testimonial
