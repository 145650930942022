import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class TitleColumns extends Component {
  render() {
  	const props = this.props
    return (
      <section className='content content--people'>
        <div className='content__inner'>
          <Fade bottom distance='40px'>
            <div className='content__intro' dangerouslySetInnerHTML={{ __html: props.title }} />
          </Fade>
          <div className='content__items'>
            { props.columns.map((el, i) => {
              return (
                <Fade bottom distance='40px' key={i} delay={1000}>
                  <div className='content__item'>
                    <div dangerouslySetInnerHTML={{ __html: el.content }} />
                  </div>
                </Fade>
              )
            }) }
          </div>
        </div>
      </section>
    )
  }
}

export default TitleColumns
