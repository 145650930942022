import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Video extends Component {

  constructor(props) {
    super(props)

    let iframe = this.props.video.indexOf('iframe') !== -1

    this.state = {
      active: false,
      video: '',
      iframe,
    }
  }

  showVideo = (video) => {
    this.setState({ active: true, video: video }, () => {
      let src = document.querySelector('.content__iframe iframe').getAttribute('src')
      src += '&autoplay=1'
      document.querySelector('.content__iframe iframe').setAttribute('src', src)
    });
  }

  render() {
    const { video_poster, video } = this.props
    let { active, iframe } = this.state


    if (iframe) {
      return (
        <section className='content content--video'>
          <div className='content__inner'>
            <div className="content__iframe">
              { !active && video_poster &&
                <Fade bottom distance='40px'>
                  <picture onClick={() => this.showVideo(video)} >
                    <img src={video_poster.localFile.childImageSharp.original.src} alt={video_poster.alt_text} />

                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
                      <path d="M256,0C114.617,0,0,114.615,0,256s114.617,256,256,256s256-114.615,256-256S397.383,0,256,0z M344.48,269.57l-128,80c-2.59,1.617-5.535,2.43-8.48,2.43c-2.668,0-5.34-0.664-7.758-2.008C195.156,347.172,192,341.82,192,336V176c0-5.82,3.156-11.172,8.242-13.992c5.086-2.836,11.305-2.664,16.238,0.422l128,80c4.676,2.93,7.52,8.055,7.52,13.57S349.156,266.641,344.48,269.57z"/>
                    </svg>

                  </picture>
                </Fade>
              }
              <div dangerouslySetInnerHTML={{__html: video}} />
            </div>
          </div>
        </section>
      )
    }

    return (
      <section className='content content--video'>
        <div className='content__inner'>
          <Fade bottom distance='40px'>
            <video
              src={video}
              poster={video_poster?.localFile?.childImageSharp?.original?.src}
              title={video_poster?.alt_text}
              autoPlay
              playsInline
              muted
              loop
            />
          </Fade>
        </div>
      </section>
    )
  }
}

export default Video
