import React from 'react'

import People from '../components/people'
import TitleColumns from '../components/title-columns'
import Projects from '../components/projects'
import Image from '../components/image'
import Video from '../components/video'
import Testimonial from '../components/testimonial'
import Subscribe from '../components/subscribe'
import BasicContent from '../components/basic-content'

const renderBlock = (param, el, i, page={}) => {
  let block = {
    'WordPressAcf_basic_content': (el, i) => (<BasicContent {...el} key={i} />),
    'WordPressAcf_people': (el, i) => (<People {...el} key={i} />),
    'WordPressAcf_title_columns': (el, i) => (<TitleColumns {...el} key={i} />),
    'WordPressAcf_projects': (el, i) => (<Projects {...el} key={i} />),
    'WordPressAcf_image': (el, i) => (<Image {...el} key={i} />),
    'WordPressAcf_video': (el, i) => (<Video {...el} key={i} />),
    'WordPressAcf_testimonial': (el, i) => (<Testimonial {...el} key={i} />),
    'WordPressAcf_subscribe': (el, i) => (<Subscribe {...el} key={i} />),
  }[param]

  if (!block) return

  return block(el, i)
}

export default renderBlock
