import React, { Component } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

class Subscribe extends Component {

  static defaultProps = {
    title: 'Subscribe',
  }

  state = {
    result: '',
    msg: ''
  };

  _handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  _handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(this.state.email, {
      FNAME: this.state.fname,
      LNAME: this.state.lname
    })

    this.setState({
      result: result.result,
      msg: result.msg
    })
  }

  render() {
    return (
      <section className="subscribe">
        <div className="subscribe__inner">
          <h2>{ this.props.title }</h2>
          <div className="subscribe__form">
            <form onSubmit={this._handleSubmit}>
              <input className="fname" type="text" onChange={this._handleChange} placeholder="First Name" name="fname" />
              <input className="lname" type="text" onChange={this._handleChange} placeholder="Last Name" name="lname" />
              <input className="email" type="email" onChange={this._handleChange} placeholder="Email Address" name="email" />
              <button className="submit" type="submit">Submit</button>
            </form>
            { this.state.result !== '' &&
              <p dangerouslySetInnerHTML={{ __html: this.state.msg }} />
            }
          </div>
        </div>
      </section>
    )
  }
}

export default Subscribe
