import React, { Component } from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'

class Projects extends Component {

  getImageSrc(obj) {
    let src = obj && obj.localFile && obj.localFile.childImageSharp && obj.localFile.childImageSharp.original.src
    if (obj && obj.source_url.indexOf('.gif') !== -1) {
      src = obj.source_url
    }
    return src
  }

  render() {
  	const props = this.props
    return (
      <section className='projects'>
        <div className='projects__inner'>
          <div className='projects__items'>
          { props.featured_project &&
            props.featured_project.map((el, i) => {
              return (
                <Fade bottom distance='40px' key={i}>
                  <div className='projects__item projects__item--feature'>
                    <Link to={`/${el.post_name}`}>
                      <figure className="projects__image">
                        <img src={this.getImageSrc(el.acf.featured_image)} alt={el.post_title} />
                      </figure>
                      <h4>{el.post_title} <span>{el.acf.project_type}</span></h4>
                    </Link>
                  </div>
                </Fade>
              )
            })
          }
          { props.projects &&
            props.projects.map((el, i) => {
              return (
                <Fade bottom distance='40px' key={i}>
                  <div className='projects__item'>
                    <Link to={`/${el.post_name}`}>
                      <figure className="projects__image">
                        <img src={this.getImageSrc(el.acf.featured_image)} alt={el.post_title} />
                      </figure>
                      <h4>{el.post_title} <span>{el.acf.project_type}</span></h4>
                    </Link>
                  </div>
                </Fade>
              )
            })
          }
          </div>
        </div>
      </section>
    )
  }
}

export default Projects
