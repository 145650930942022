import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class BasicContent extends Component {
  render() {
    const { content } = this.props

    return (
      <section className='content content--basic'>
        <Fade bottom distance='40px'>
          <div className='content__inner' dangerouslySetInnerHTML={{__html: content }} />
        </Fade>
      </section>
    )
  }
}

export default BasicContent


